import React, { useState } from "react";
import { ImageEditorModalProps } from "../../types/modal";
import defaultImage from "../../assets/images/default-img.jpg";
import fileApi from "../../api/fileApi";

export default function ImageEditorModal(
  props: Readonly<ImageEditorModalProps>
) {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setImageFile(file);
    }
  };

  const handleFileRemoval = async () => {
    try {
      // TODO: call BE with image node data to remove the file from storage & update the DB record
      setImagePreview(null);
      setImageFile(null);
      props.onClose();
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {
    if (!imageFile || !props.image_node_id) return;

    const formData = new FormData();
    formData.append('file', imageFile);

    // append file identifier properties as text fields
    formData.append('design_id', props.design_id);
    formData.append('frame_id', props.frame_id);
    formData.append('image_node_id', props.image_node_id);

    try {
      setIsLoading(true);
      const response = await fileApi.uploadFile(formData);

      if (response.data.statusCode !== 200) {
        console.error(response.statusText);
        throw new Error(response.statusText);
      }

      // send back the object URL from S3
      props.handleSave(response.data.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`${"modal"} ${props.open ? "display-block" : "display-none"}`}
    >
      <div
        className="modal-main"
      >
        <div className="modal-head">
          <h2>Update Image</h2>
        </div>
        <div className="modal-body d-block">
          <input className="input-field" type="file" accept=".png, .jpeg" onChange={handleFileSelection} disabled={isLoading} />
          <img src={imagePreview ?? defaultImage} className="img-preview mt-3 mb-3 mx-auto" alt="Image Preview" style={{ width: '300px' }} />
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleFileRemoval}
          >
            Remove
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div >
  );
}
