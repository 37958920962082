import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import figmaApi from "../api/figmaApi";
import {
  TextNode,
  FigmaFrameIdentifier,
  UpdateFigmaFrameRequestData,
  FigmaData,
  ImageNode,
} from "../types";
import TextEditorModal from "../components/modals/TextEditorModal";
import SvgView from "../components/SvgView";
import { PathParameters } from "../types/path-parameters";
import ImageEditorModal from "../components/modals/ImageEditorModal";

/**
 * Renders the image to edit the text elements on for a frame.
 * @returns
 */
function SingleFrameView() {
  const { design_id, frame_id } = useParams<PathParameters>();

  const navigate = useNavigate();

  const hasFetchedData = useRef(false);
  const [data, setData] = useState<FigmaData | null>(null);

  const [selectedText, setSelectedText] = useState<string>("");
  const [selectedTextId, setSelectedTextId] = useState<string>("");

  const [selectedImageNode, setSelectedImageNode] = useState<ImageNode | null>(null);

  const [hyperlink, setHyperlink] = useState<string>("");
  const [characterWarningLength, setCharacterWarningLength] =
    useState<number>(0);
  const [characterBlockLength, setCharacterBlockLength] = useState<number>(0);

  const [showTextEditorModal, setShowTextEditorModal] =
    useState<boolean>(false);
  const [showImageEditorModal, setShowImageEditorModal] =
    useState<boolean>(false);
  const [updatedTextNodes, setUpdatedTextNodes] = useState<Array<TextNode>>([]);
  const [updatedImageNodes, setUpdatedImageNodes] = useState<Array<ImageNode>>([]);

  const [imageUrl, setImageUrl] = useState<string>("");
  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);

  const getFigmaFrameContent = async (data: FigmaFrameIdentifier) => {
    try {
      const response = await figmaApi.getFigmaFrame(data);

      if (response.data.statusCode !== 200) {
        throw new Error("No Figma data returned");
      }
      
      setData(response.data.data);
      setImageUrl(`${response.data.data.image_url}?${new Date().getTime()}`);
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        toast(error.message, { type: "error" });
      }
      navigate(`/${design_id}`);
    }
  };

  useEffect(() => {
    if (!design_id || !frame_id || hasFetchedData.current) return;
    hasFetchedData.current = true;

    setIsImageLoad(false);
    getFigmaFrameContent({ design_id, frame_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [design_id, frame_id]);

  const toggleTextEditorModal = () => {
    setShowTextEditorModal(!showTextEditorModal);
  };

  const toggleImageEditorModal = () => {
    setShowImageEditorModal(!showImageEditorModal);
  };

  const onStringChange = (value: string) => {
    setSelectedText(value);
  };

  const onHyperlinkChange = (value: string) => {
    setHyperlink(value);
  }

  const handleTextClick = (node: TextNode) => {
    setSelectedText(node.characters);
    setSelectedTextId(node.id);
    setCharacterWarningLength(node.characterWarningLength);
    setCharacterBlockLength(node.characterBlockLength);
    if (node.hyperlink) {
      setHyperlink(node.hyperlink);
    } else {
      setHyperlink("");
    }
    toggleTextEditorModal();
  };

  const handleImageClick = (node: ImageNode) => {
    setSelectedImageNode(node);
    toggleImageEditorModal();
  }

  const updateFigmaFrameContent = async (data: UpdateFigmaFrameRequestData) => {
    try {
      const response = await figmaApi.updateFigmaFrameContent(data);
      if (response.status !== 200) {
        toast(response.data.message, { type: "error" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTextSave = () => {
    if (!data) return;
    let editedNode: any = {};

    const updatedTextNodes = data.text_nodes.map((node) => {
      const isTextNodeUpdated = node.characters !== selectedText || node.hyperlink !== hyperlink;
      if (node.id === selectedTextId && isTextNodeUpdated) {
        const hyperLink = hyperlink.length > 0 ? hyperlink : null;
        editedNode = { ...node, characters: selectedText, hyperlink: hyperLink };
        setUpdatedTextNodes((prevNodes) => [...prevNodes, editedNode]);
        return { ...node, characters: selectedText, hyperlink: hyperLink };
      }
      return node;
    });
    setData({ ...data, text_nodes: updatedTextNodes });
    
    updateFigmaFrameContent({
      design_id: data.design_id,
      frame_id: data.frame_id,
      text_nodes: [editedNode],
    });
    toggleTextEditorModal();
  };

  const handleImageSave = async (imageUrl: string) => {
    if (!data) return;
    let editedNode: any = {};

    try {
      const updatedImageNodes = data.image_nodes.map((node) => {       
        if (node.id === selectedImageNode?.id && imageUrl !== null) {
          editedNode = { ...node, imageUrl };
          setUpdatedImageNodes((prevNodes) => [...prevNodes, editedNode]);
          return { ...node, imageUrl };
        }
        return node;
      });
      setData({ ...data, image_nodes: updatedImageNodes });

      const response = await figmaApi.updateFigmaFrameContent({
        design_id: data.design_id,
        frame_id: data.frame_id,
        image_nodes: [editedNode],
      });

      if (response.status !== 200) {
        toast(response.data.message, { type: "error" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleImageEditorModal();
    }
  }

  return (
    <div className="page-content-wrapper frame-view">
      {!isImageLoad && (
        <div className="loader-overlay">
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      {data && (
        <>
          <SvgView
            data={data}
            imageUrl={imageUrl}
            updatedTextNodes={updatedTextNodes}
            handleTextClick={handleTextClick}
            isImageLoad={isImageLoad}
            setIsImageLoad={setIsImageLoad}
            handleImageClick={handleImageClick}
            updatedImageNodes={updatedImageNodes}
          />
        </>
      )}
      <TextEditorModal
        open={showTextEditorModal}
        onClose={toggleTextEditorModal}
        text={selectedText}
        hyperlink={hyperlink}
        characterWarningLength={characterWarningLength}
        characterBlockLength={characterBlockLength}
        onStringChange={onStringChange}
        onHyperlinkChange={onHyperlinkChange}
        handleSave={handleTextSave}
      />
      <ImageEditorModal
        frame_id={frame_id ?? ''}
        design_id={design_id ?? ''}
        open={showImageEditorModal}
        onClose={toggleImageEditorModal}
        handleSave={handleImageSave}
        image_node_id={selectedImageNode?.id}
      />
    </div>
  );
}

export default SingleFrameView;
