import axiosApi from ".";
import { API_FILE_UPLOAD } from "../constants";
import { APIResponse } from "../types";

/**
 * Send multipart/form-data post request to the server with the provided formdata object.
 * @param data 
 * @returns 
 */
const uploadFile = async (data: FormData) => {
    return axiosApi.post<APIResponse<string>>(API_FILE_UPLOAD, data, { headers: { 'content-type': 'multipart/form-data' } });
};

const fileApi = {
    uploadFile
};

export default fileApi;