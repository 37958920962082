/**
 * Contains common utility functions
 */

/**
 * Uppercase each word in the provided string
 * @param str
 * @returns
 */
export function uppercaseWords(str: string) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

export function countLines(str: string) {
  return str.split("\n").length;
}

/**
 * Unsecured fallback for copying text to clipboard
 * @param text - The text to be copied to the clipboard
 */
const _unsecuredCopyToClipboard = (text: string) => {
  // Create a textarea element
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);

  // Focus and select the textarea content
  textArea.focus();
  textArea.select();

  // Attempt to copy the text to the clipboard
  try {
    document.execCommand("copy");
  } catch (e) {
    console.error("Unable to copy content to clipboard!", e);
  }

  // Remove the textarea element from the DOM
  document.body.removeChild(textArea);
};

/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
 *
 * Inspired by: https://stackoverflow.com/questions/71873824/copy-text-to-clipboard-cannot-read-properties-of-undefined-reading-writetext
 * and https://forum.figma.com/t/write-to-clipboard-from-custom-plugin/11860/12
 *
 * @param content - The content to be copied to the clipboard
 **/
export const copyToClipboard = (content: string) => {
  // If the context is secure and clipboard API is available, use it
  if (
    window.isSecureContext &&
    typeof navigator?.clipboard?.writeText === "function"
  ) {
    navigator.clipboard.writeText(content);
  }
  // Otherwise, use the unsecured fallback
  else {
    _unsecuredCopyToClipboard(content);
  }
};

/**
 * Return a formatted date string.
 * @param dateString
 * @returns
 */
export const formatDateString = (dateString: string) => {
  return new Date(dateString).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short", // Optional: to show the timezone
  });
};

/**
 * Check if the provided string is a valid URL.
 * Empty string is accepted as it is used to clear the value in input field.
 * @param url 
 * @returns 
 */
export const isValidURL = (url: string | null) => {
  // valid in this case as empty string is accepted as clear value
  if (!url) return true;

  const urlRegex = /\b(https?:\/\/[-\w@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))\b/;
  const mailtoRegex = /^mailto:[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
  const telRegex = /^tel:\+?[0-9]{1,15}$/;

  return urlRegex.test(url) || mailtoRegex.test(url) || telRegex.test(url);
}